
import React from 'react';
import { Box, Heading, Text, SimpleGrid, Image, Button, VStack, useToast } from '@chakra-ui/react';
import { handleClick } from './Home';

const LatinGirls = () => {
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Sexy Latin Girls on TheFxxk.com
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Discover the passion and beauty of the sexiest Latin girls in our erotic chat. Connect with beautiful women from all over Latin America and experience unique encounters.
      </Text>
      
      <SimpleGrid columns={[1, 2, 3]} spacing="20px" mb="10">
        <Image src="/girl1.png" alt="Sexy Latin girl" borderRadius="md" />
        <Image src="/girl2.png" alt="Beautiful Latina on webcam" borderRadius="md" />
        <Image src="/girl3.png" alt="Sensual Latin model" borderRadius="md" />
      </SimpleGrid>
      
      <VStack spacing="6" align="stretch">
        <Heading as="h2" size="xl">
          Why choose Latin girls?
        </Heading>
        <Text fontSize="lg">
          Latin girls are known for their exotic beauty, passionate personality, and unique charisma. On TheFxxk.com, you can enjoy:
        </Text>
        <Box>
          <Text fontSize="lg" as="ul" pl="6">
            <li>Natural beauty and sensual curves</li>
            <li>Warm and welcoming personalities</li>
            <li>Diverse cultural experiences</li>
            <li>Spicy conversations in Spanish and English</li>
            <li>Live shows full of Latin passion</li>
          </Text>
        </Box>
      </VStack>
      
      <Box textAlign="center" mt="10">
        <Button
          colorScheme="pink"
          size="lg"
          as="a"
          href="https://t.me/DreamGFXXXBot"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClick(toast)}
        >
          Chat for free now with Latin girls
        </Button>
      </Box>
    </Box>
  );
};

export default LatinGirls;
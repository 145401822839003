import React from 'react';
import { Box, Heading, Text, VStack, Button, useToast } from '@chakra-ui/react';
import { handleClick } from './Home';

const VirtualDating = () => {
  const toast = useToast();

  return (
    <Box maxWidth="1200px" margin="auto" padding="20px">
      <Heading as="h1" size="2xl" textAlign="center" mb="6">
        Virtual Dating with Latin Girls
      </Heading>
      
      <Text fontSize="xl" mb="8">
        Experience the thrill of a real date from the comfort of your home with our virtual dates.
      </Text>
      
      <VStack spacing="6" align="stretch">
        <Text fontSize="lg">
          Our virtual dates include:
        </Text>
        <Box>
          <Text fontSize="lg" as="ul" pl="6">
            <li>Private video calls</li>
            <li>Interactive games</li>
            <li>Personalized experiences</li>
            <li>Virtual gift option</li>
          </Text>
        </Box>
      </VStack>
      
      <Box textAlign="center" mt="10">
        <Button colorScheme="pink" size="lg"
        as="a"
        href="https://t.me/DreamGFXXXBot"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleClick(toast)}>
          Book Your Virtual Date
        </Button>
      </Box>
    </Box>
  );
};

export default VirtualDating;